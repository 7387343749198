import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useCallback, useEffect, useState } from "react"
import BlogCategories from "../components/blog/blog-categories"
import DownloadPopup from "../components/download-popup"
import Footer from "../components/footer"
import Menu from "../components/menu/menu"
import MenuMobile from "../components/menu/menu-mobile"
import SEO from "../components/seo"
import { BlogArticleType, ContentfulBlogArticleType } from "../types/blog-types"
import { stringToPath } from "../utilities/string-path-convertor"
import ContentfulRichText from "../utilities/contentful-rich-text"
import BlogAuthor from "../components/blog/blog-author"
import ContentfulRichTextContentTable from "../utilities/contentful-rich-text-content-table"
import BlogSharer from "../components/blog/blog-sharer"
import RedingTime from "../components/blog/reading-time"

export const query = graphql`
  query BlogArticleQuery($slug: String!, $categoryId: String) {
    contentfulBlogArticle(
      compose__page: { elemMatch: { slug: { eq: $slug } } }
    ) {
      createdAt(formatString: "D MMMM, YYYY")
      id
      color
      category {
        name
        id
      }
      text {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            id
            contentful_id
            description
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
          ... on ContentfulComponentSlotsTypes {
            __typename
            id
            contentful_id
            slots {
              id
              compose__page {
                slug
                title
              }
              categories {
                title
                id
              }
              gameIframeUrl
              slotInfoLink
              slotIcon {
                localFile {
                  childImageSharp {
                    gatsbyImageData(quality: 40)
                  }
                }
                title
              }
              description {
                raw
              }
            }
          }
          ... on ContentfulComponentYoutubeVideo {
            id
            contentful_id
            __typename
            youtubeUrl {
              youtubeUrl
            }
          }
          ... on ContentfulComponentHtmlCode {
						id
            contentful_id
            __typename
            html {
              html
            }
          }
        }
      }
      author {
        name
        description {
          raw
        }
        avatar {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 150)
            }
          }
        }
      }
      image {
        title
        localFile {
          childImageSharp {
            gatsbyImageData(width: 240)
          }
        }
      }
      compose__page {
        title
        slug
        id
        impressionLink {
          impressionLink
        }
        seo {
          no_index
          no_follow
          keywords
          description
          title
          redirect302
          redirect301
          socialImage {
            url
          }
        }
      }
    }

    allContentfulBlogArticle(
      filter: {
        category: { id: { eq: $categoryId } }
        compose__page: { elemMatch: { slug: { ne: $slug } } }
      }
      limit: 4
      sort: { fields: compose__page___createdAt, order: DESC }
    ) {
      edges {
        node {
          id
          color
          compose__page {
            title
            slug
          }
          image {
            title
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`

const BlogArticleTemplate = ({
  location,
  data: {
    contentfulBlogArticle: article,
    allContentfulBlogArticle: relatedArticle,
  },
}: {
  location: any
  data: {
    contentfulBlogArticle: BlogArticleType
    allContentfulBlogArticle: ContentfulBlogArticleType["allContentfulBlogArticle"]
  }
}) => {
  const [showDownloadPopup, setShowDownloadPopup] = useState<boolean>(false)
  const seo = {
    title: article.compose__page[0].title,
    description: article.compose__page[0].description,
  }


  const [highlights, setHighlights] = useState({})
  const highlightItems = useCallback(() => {
    const heading = document.querySelectorAll(".article-content h2")

    heading.forEach((h) => {
      const rect = h.getBoundingClientRect()
      const inView =
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth)
      setHighlights((highlights) => ({
        ...highlights,
        [h.id]: rect.top < 0 || inView,
      }))
    })
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", highlightItems)

    return () => window.addEventListener("scroll", highlightItems)
  }, [])

  return (
    <>
      <SEO data={seo} impressionLink={article?.compose__page?.[0]?.impressionLink?.impressionLink} />

      <DownloadPopup
        onCloseClick={() => setShowDownloadPopup(false)}
        show={showDownloadPopup}
      />

      <div className='app-container blog-container'>
        <MenuMobile onDownloadClick={() => setShowDownloadPopup(true)} />
        <Menu
          hideOnScroll={false}
          onDownloadClick={() => setShowDownloadPopup(true)}
        />

        <main>
          {!!article.category && <BlogCategories activeCategory={article.category.name} />}
          <div className='blog-article-page '>
            <div
              className='article-page-top-info py-10'
              style={{ backgroundColor: article.color }}
            >
              <div className='article-page-top-info-container flex xl:justify-between xl:items-center'>
                <div className='flex flex-col space-y-10 xl:pl-[60px]'>
                  <div className='blog-article-breadcrumbs capitalize flex items-center space-x-3'>
                    <Link to='/'>Home</Link>
                    <span>/</span>
                    <Link to='/blog'>Blog</Link>
                    {!!article.category &&
                      <>
                        <span>/</span>
                        <Link to={`/blog/${stringToPath(article.category.name)}`}>
                          {article.category.name.toLowerCase()}
                        </Link>
                      </>
                    }
                  </div>
                  <h1 className='font-bold text-[24px] leading-[28px] xl:text-[48px] xl:leading-[50px] max-w-[680px]'>
                    {article.compose__page[0].title}
                  </h1>
                  <div className='text-[12px] xl:text-[14px] flex space-x-5 xl:space-x-10'>
                    {article.author && (
                      <div className=''>{article.author.name}</div>
                    )}
                    <div className=''>{article.createdAt}</div>
                    <div className=''>
                      <RedingTime text={article.text.raw} />
                    </div>
                  </div>
                  <div className='block xl:hidden'>
                    <GatsbyImage
                      image={
                        getImage(
                          article.image.localFile.childImageSharp
                            .gatsbyImageData
                        )!
                      }
                      alt={article.image.title}
                    />
                  </div>
                </div>
                <div className='hidden xl:block'>
                  <GatsbyImage
                    image={
                      getImage(
                        article.image.localFile.childImageSharp.gatsbyImageData
                      )!
                    }
                    alt={article.image.title}
                  />
                </div>
              </div>
            </div>
            <div className='article-content-container pb-[50px]'>
              <div className='relative'>
                <div className='article-content'>
                  <div className='table-of-contents'>
                    <div className='text-[#202135] font-bold text-[23px] mb-5'>
                      TABLE OF CONTENTS
                    </div>
                    <ul>
                      <ContentfulRichTextContentTable
                        raw={article.text}
                        highlights={highlights}
                      />
                    </ul>
                  </div>
                  <div className='article-text float-left w-full xl:w-10/12 xl:pl-10'>
                    <ContentfulRichText raw={article.text} />
                    <div className='flex flex-col-reverse xl:flex-col'>
                      <div className='my-10'>
                        <BlogSharer
                          url={
                            typeof window !== "undefined"
                              ? window.location.href
                              : ""
                          }
                        />
                      </div>
                      <BlogAuthor author={article.author} />
                    </div>
                  </div>
                  <div className='clear-both'></div>
                </div>
              </div>
            </div>
            {/* <div className='article-content-container hidden xl:block'>
                <div className='blog-main-container'>
                  <div className='related-articles py-20'>
                    <div className='text-[#202135] text-[50px] font-bold'>
                      Realted Articles
                    </div>
                    <div className='flex space-x-12 justify-between'>
                      {relatedArticle.edges.map(({ node: relatedArticle }) => (
                        <div
                          className='related-article w-1/4'
                          key={relatedArticle.id}
                        >
                          <div
                            className='blog-article-image flex justify-center items-center p-24'
                            style={{ backgroundColor: relatedArticle.color }}
                          >
                            <Link
                              to={`/blog/${relatedArticle.compose__page[0].slug}`}
                            >
                              <GatsbyImage
                                image={
                                  getImage(
                                    relatedArticle.image.localFile
                                      .childImageSharp.gatsbyImageData
                                  )!
                                }
                                alt={relatedArticle.image.title}
                              />
                            </Link>
                          </div>
                          <div className='text-[25px] font-semibold my-5'>
                            <Link
                              className='text-black'
                              to={`/blog/${relatedArticle.compose__page[0].slug}`}
                            >
                              {relatedArticle.compose__page[0].title}
                            </Link>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
          </div>
        </main>

        <Footer />
      </div>
    </>
  )
}

export default BlogArticleTemplate
