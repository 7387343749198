import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share"

const BlogSharer = ({ url }: { url: string }) => {
  return (
    <div className='flex space-x-5 justify-center xl:justify-start items-center'>
      <div className='text-[#202135] text-[25px] font-semibold'>Share:</div>
      <FacebookShareButton
        children={
          <div className='blog-share-btn'>
            <StaticImage
              src='../../images/blog/facebook-fill.png'
              alt='Share Facebook'
            />
          </div>
        }
        url={url}
      />
      <TwitterShareButton
        url={url}
        children={
          <div className='blog-share-btn'>
            <StaticImage
              src='../../images/blog/twitter-fill.png'
              alt='Share Facebook'
            />
          </div>
        }
      />
      <EmailShareButton
        subject='Check out this article from quickhitslots.com blog'
        body='The aritcle was published on quickhitslots.com blog. Check this out:'
        url={url}
        children={
          <div className='blog-share-btn'>
            <StaticImage
              src='../../images/blog/mail-fill.png'
              alt='Share Facebook'
            />
          </div>
        }
      />
    </div>
  )
}

export default BlogSharer
